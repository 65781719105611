import React from "react";
import logo from "./dd.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>We've been dormant for a while, now. We'll be back soon for sure!</p>
        <a
          className="App-link"
          href="https://www.deccanchronicle.com/lifestyle/viral-and-trending/141116/the-dare-deliverer.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Read about us on the Deccan Chronicle
        </a>
      </header>
      <div className="footer">
        Copyright &copy;{" "}
        <a
          href="https://www.linkedin.com/in/anoop-1507/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Anoop Santhanam
        </a>{" "}
        {new Date().getFullYear()}
      </div>
    </div>
  );
}

export default App;
